<template>
  <aside id="logo-sidebar" class="top-0 left-0 w-full z-40 max-w-128 h-screen pt-5 transition-transform -translate-x-full bg-white border-gray-200 sm:translate-x-0 bg-gray-900" aria-label="Sidebar">
    <div class="fixed top-0 left-0 h-full px-3 pb-4 overflow-y-auto bg-gray-900 w-full">
      <img src="https://resource-cdn.mindstamp.com/logos/v2/full/white.png" class="w-4/5 pl-2 pt-6 pb-4">
      <ul class="space-y-1 font-light">
        <li>
          <router-link to="/" class="flex items-center p-2 rounded-lg text-white border-2 border-transparent">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M16 5C16 4.06812 16 3.60218 16.1522 3.23463C16.3552 2.74458 16.7446 2.35523 17.2346 2.15224C17.6022 2 18.0681 2 19 2C19.9319 2 20.3978 2 20.7654 2.15224C21.2554 2.35523 21.6448 2.74458 21.8478 3.23463C22 3.60218 22 4.06812 22 5V9C22 9.93188 22 10.3978 21.8478 10.7654C21.6448 11.2554 21.2554 11.6448 20.7654 11.8478C20.3978 12 19.9319 12 19 12C18.0681 12 17.6022 12 17.2346 11.8478C16.7446 11.6448 16.3552 11.2554 16.1522 10.7654C16 10.3978 16 9.93188 16 9V5Z" stroke="currentColor" stroke-width="1.5" />
              <path d="M16 19C16 18.0681 16 17.6022 16.1522 17.2346C16.3552 16.7446 16.7446 16.3552 17.2346 16.1522C17.6022 16 18.0681 16 19 16C19.9319 16 20.3978 16 20.7654 16.1522C21.2554 16.3552 21.6448 16.7446 21.8478 17.2346C22 17.6022 22 18.0681 22 19C22 19.9319 22 20.3978 21.8478 20.7654C21.6448 21.2554 21.2554 21.6448 20.7654 21.8478C20.3978 22 19.9319 22 19 22C18.0681 22 17.6022 22 17.2346 21.8478C16.7446 21.6448 16.3552 21.2554 16.1522 20.7654C16 20.3978 16 19.9319 16 19Z" stroke="currentColor" stroke-width="1.5" />
              <path d="M2 16C2 14.1144 2 13.1716 2.58579 12.5858C3.17157 12 4.11438 12 6 12H8C9.88562 12 10.8284 12 11.4142 12.5858C12 13.1716 12 14.1144 12 16V18C12 19.8856 12 20.8284 11.4142 21.4142C10.8284 22 9.88562 22 8 22H6C4.11438 22 3.17157 22 2.58579 21.4142C2 20.8284 2 19.8856 2 18V16Z" stroke="currentColor" stroke-width="1.5" />
              <path d="M2 5C2 4.06812 2 3.60218 2.15224 3.23463C2.35523 2.74458 2.74458 2.35523 3.23463 2.15224C3.60218 2 4.06812 2 5 2H9C9.93188 2 10.3978 2 10.7654 2.15224C11.2554 2.35523 11.6448 2.74458 11.8478 3.23463C12 3.60218 12 4.06812 12 5C12 5.93188 12 6.39782 11.8478 6.76537C11.6448 7.25542 11.2554 7.64477 10.7654 7.84776C10.3978 8 9.93188 8 9 8H5C4.06812 8 3.60218 8 3.23463 7.84776C2.74458 7.64477 2.35523 7.25542 2.15224 6.76537C2 6.39782 2 5.93188 2 5Z" stroke="currentColor" stroke-width="1.5" />
            </svg>
            {{ $t('nav.homepage') }}
          </router-link>
        </li>
        <li>
          <router-link to="/videos" class="flex items-center p-2 rounded-lg text-white border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/videos' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M17.7 21.3351C16.528 21.4998 14.9996 21.4998 12.95 21.4998H11.05C7.01949 21.4998 5.00424 21.4998 3.75212 20.2476C2.5 18.9955 2.5 16.9803 2.5 12.9498V11.0498C2.5 7.01925 2.5 5.00399 3.75212 3.75187C5.00424 2.49976 7.01949 2.49976 11.05 2.49976H12.95C16.9805 2.49976 18.9958 2.49976 20.2479 3.75187C21.5 5.00399 21.5 7.01925 21.5 11.0498V12.9498C21.5 14.158 21.5 15.1851 21.4663 16.0648C21.4392 16.7699 21.4257 17.1224 21.1587 17.2541C20.8917 17.3859 20.5931 17.1746 19.9958 16.752L18.65 15.7998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.9453 12.3948C14.7686 13.0215 13.9333 13.4644 12.2629 14.3502C10.648 15.2064 9.8406 15.6346 9.18992 15.4625C8.9209 15.3913 8.6758 15.2562 8.47812 15.07C8 14.6198 8 13.7465 8 12C8 10.2535 8 9.38018 8.47812 8.92995C8.6758 8.74381 8.9209 8.60868 9.18992 8.53753C9.8406 8.36544 10.648 8.79357 12.2629 9.64983C13.9333 10.5356 14.7686 10.9785 14.9453 11.6052C15.0182 11.8639 15.0182 12.1361 14.9453 12.3948Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            </svg>  
            {{ $t('nav.videos') }}
          </router-link>
        </li>

        <li>
          <router-link to="/reporting" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/reporting' }">
            <svg class="text-white mr-2" xmlns="//www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg>
            {{ $t('nav.reporting') }}
          </router-link>
        </li>


        <!-- Only show if current tab is reporting -->
        <div v-if="$route.path.includes('/reporting')" class="ml-8">
          <li>
            <router-link to="/reporting/views" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path.includes('/reporting/views') }">
              <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                <path d="M21.544 11.045C21.848 11.4713 22 11.6845 22 12C22 12.3155 21.848 12.5287 21.544 12.955C20.1779 14.8706 16.6892 19 12 19C7.31078 19 3.8221 14.8706 2.45604 12.955C2.15201 12.5287 2 12.3155 2 12C2 11.6845 2.15201 11.4713 2.45604 11.045C3.8221 9.12944 7.31078 5 12 5C16.6892 5 20.1779 9.12944 21.544 11.045Z" stroke="currentColor" stroke-width="1.5" />
                <path d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z" stroke="currentColor" stroke-width="1.5" />
              </svg>  
              {{ $t('nav.views') }}
            </router-link>
          </li>
          <li>
            <router-link to="/reporting/interactions" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path.includes('/reporting/interactions') }">
              <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                <path d="M8.62814 12.6736H8.16918C6.68545 12.6736 5.94358 12.6736 5.62736 12.1844C5.31114 11.6953 5.61244 11.0138 6.21504 9.65083L8.02668 5.55323C8.57457 4.314 8.84852 3.69438 9.37997 3.34719C9.91142 3 10.5859 3 11.935 3H14.0244C15.6632 3 16.4826 3 16.7916 3.53535C17.1007 4.0707 16.6942 4.78588 15.8811 6.21623L14.8092 8.10188C14.405 8.81295 14.2029 9.16849 14.2057 9.45952C14.2094 9.83775 14.4105 10.1862 14.7354 10.377C14.9854 10.5239 15.3927 10.5239 16.2074 10.5239C17.2373 10.5239 17.7523 10.5239 18.0205 10.7022C18.3689 10.9338 18.5513 11.3482 18.4874 11.7632C18.4382 12.0826 18.0918 12.4656 17.399 13.2317L11.8639 19.3523C10.7767 20.5545 10.2331 21.1556 9.86807 20.9654C9.50303 20.7751 9.67833 19.9822 10.0289 18.3962L10.7157 15.2896C10.9826 14.082 11.1161 13.4782 10.7951 13.0759C10.4741 12.6736 9.85877 12.6736 8.62814 12.6736Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              </svg>
              {{ $t('nav.interactions') }}
            </router-link>
          </li>
          <li>
            <router-link to="/reporting/viewers" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path.includes('/reporting/viewers') }">
              <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 15C8.91212 16.2144 10.3643 17 12 17C13.6357 17 15.0879 16.2144 16 15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.00897 9L8 9M16 9L15.991 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              {{ $t('nav.viewers') }}
            </router-link>
          </li>
        </div>

        <!-- Integrations -->
        <li>
          <router-link to="/integrations" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
            </svg>
            {{ $t('nav.integrations') }}
          </router-link>
        </li>

        <!-- Conditional integrations -->
        <div v-if="$route.path.includes('/integrations')" class="ml-8">
          <!-- TODO these icons -->
          <li>
            <router-link to="/integrations/google-analytics" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/google-analytics' }">
              <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-analytics-icon.png" class="w-4 h-4 rounded-lg mr-2">
              {{ $t('integrations.google_analytics') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/facebook" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/facebook' }">
              <img src="https://cdn.pixabay.com/photo/2021/06/15/12/51/facebook-6338507_1280.png" class="w-4 h-4 rounded-lg mr-2">
              {{ $t('integrations.facebook') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/zapier" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/zapier' }">
              <img src="https://www.svgrepo.com/show/354596/zapier-icon.svg" class="w-4 h-4 rounded-lg mr-2">
              {{ $t('integrations.zapier') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/hubspot" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/hubspot' }">
              <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/hubspot-icon.png" class="w-4 h-4 rounded-lg mr-2">
              {{ $t('integrations.hubspot') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/shopify" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/shopify' }">
              <img src="https://static-00.iconduck.com/assets.00/shopify-icon-1801x2048-byf9jpjn.png" class="w-4 h-4 rounded-lg mr-2">{{ $t('integrations.shopify') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/synthesia" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/synthesia' }">
              <img src="https://logosandtypes.com/wp-content/uploads/2024/05/synthesia.svg" class="w-4 h-4 rounded-lg mr-2">{{ $t('integrations.synthesia') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/stripe" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/stripe' }">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQGluJhW7I1NYU7jF77E-9K9I46_ib_DUNHw&s" class="w-4 h-4 rounded-lg mr-2">{{ $t('integrations.stripe') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/constant-contact" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/constant-contact' }">
              <img src="https://www.smarsh.com/media/constant-contact-logo-icon.png" class="w-4 h-4 rounded-lg mr-2">{{ $t('integrations.constant_contact') }}
            </router-link>
          </li>
          <li>
            <router-link to="/integrations/openai" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/integrations/openai' }">
              <img src="https://static.vecteezy.com/system/resources/previews/022/227/364/non_2x/openai-chatgpt-logo-icon-free-png.png" class="w-4 h-4 rounded-lg mr-2">{{ $t('integrations.openai') }}
            </router-link>
          </li>

        </div>



        <!-- Groups -->
        <li>
          <router-link to="/groups" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/groups' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
            </svg>  
            {{ $t('nav.groups') }}
          </router-link>
        </li>

        <!-- Series -->
        <li>
          <router-link to="/series" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/series' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
            {{ $t('nav.series') }} 
          </router-link>
        </li>
        <li>
          <router-link to="/help" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/help' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
            </svg>
            {{ $t('nav.help') }}
          </router-link>
        </li>

        <!-- Affiliates -->
        <li>
          <router-link to="/affiliates" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/affiliates' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            {{ $t('nav.affiliates') }}
          </router-link>
        </li>

        <!-- Assets -->
        <li>
          <router-link to="/assets" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/assets' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
            </svg>
            {{ $t('nav.assets') }}
          </router-link>
        </li>

        <!-- Design -->
        <li>
          <router-link to="/design" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/design' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
            </svg>
            {{ $t('nav.design') }}
          </router-link>
        </li>

        <!-- Account -->
        <li>
          <router-link to="/account" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/account' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            {{ $t('nav.account') }}
          </router-link>
        </li>

        <!-- Organization -->
        <li>
          <router-link to="/organization" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/organization' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
            </svg>
            {{ $t('nav.organization') }}
           </router-link>
        </li>

        <!-- Billing -->
        <li>
          <router-link to="/billing" class="flex items-center p-2 text-white rounded-lg border-2 border-transparent" :class="{ 'activeMenuItem': $route.path === '/billing' }">
            <svg class="text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
            </svg>
            {{ $t('nav.billing') }}
          </router-link>
        </li>
        
        <li>
            <a href="/users/sign_in">{{ $t('nav.login') }}</a>
        </li>
        <li>
          <form class="button_to" method="post" action="/users/sign_out">
            <input type="hidden" name="_method" value="delete" autocomplete="off">
            <button data-turbo="false" type="submit">{{ $t('nav.logout') }}</button>
            <input type="hidden"  id="authenticity_token" name="authenticity_token" autocomplete="off">
          </form>
        </li>
     </ul>
  </div>
</aside>
</template>
<script setup>
import { onMounted } from 'vue';


onMounted(() => {
  document.getElementById('authenticity_token').value = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content")
});
</script>

<style scoped>
  /* Have to do this since tailwind default a color not overridden by class */
  .activeMenuItem {
    /* color: #111827 !important; */
    /* background: rgba(255,255,255,0.2); */
    /* font-weight: bold; */
    border-color: gold !important;
    /* border: 3px solid gold !important; */
  }

  svg {
    width: 1em;
    height: 1em;
  }
</style>
<template>  
  <section id="series-filters" class="select-none">
    <div class="grid grid-cols-1">
      <div class="col-span-1 px-2 py-1" >
        <!-- <label> {{ $t('filters.search') }} </label> -->
        <!-- Search titles -->
         <!-- TODO make these autofocus -->
        <TextField 
          :autofocus="true"
          v-model="filters.name_cont" 
          id="series-filters-search" 
          ref="tableFilterSearch" 
          :label="$t('filters.search_series_name')" 
          class="w-full relative" 
          :class="getFilterClass(filters.name_cont)"

        />
      </div>
      
    </div>
    
  </section>
</template>

<script setup>
import { watch, ref, onMounted } from 'vue';

import TextField from "../views/shared/TextField.vue";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { debounce } from 'lodash';

import ButtonWhite from "./ButtonWhite.vue";

import { defineEmits } from 'vue'

const emit = defineEmits(['setActiveFilters'])

const location = useRoute();
const router = useRouter();




const filters = reactive({
  name_cont: location.query["q[name_cont]"] || '',
})


watch(
  () => filters,
  (newValue, oldValue) => {
    localStorage.setItem("seriesFilters", JSON.stringify(filters))
    debouncedLog()
  },
  { deep: true }
)
const debouncedLog = debounce(() => {
  search()
}, 500);

const getFilterClass = (arg) => {
  var str = 'rounded-lg p-1 bg-white h-10'
  if (!!arg) {
    str += ' !bg-yellow-100'
  }
  return str
}


const search = () => {
  var str = [];
  for (const property in filters) {
    if (!!filters[property] && filters[property] !== '') {
      str.push(`q[${encodeURIComponent(property)}]=${encodeURIComponent(filters[property])}`);
    }
  }
  const query = "?" + str.join("&");
  router.push(location.path + query);
}


onMounted(() => {
  
})

</script>
<style>
/* TODO: Move these to global? */
#series-filters .vs__dropdown-toggle {
  border: none;
}

#series-filters label {
  font-weight: 600;
  display: block;
  padding-bottom: 5px !important;
}

/* Remove default styling from TextField component, TODO maybe not use it? */
#series-filters-search  input {
  background: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#series-filters .dp__input {
  border: none;
}
</style>



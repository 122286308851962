<template>
  <section class="container mx-auto">
    <!-- <h1>{{ $t('home.title') }}</h1> -->
    <router-link to="/reporting/views" class="bg-gray-500 p-4 text-white rounded-md">Views</router-link>
    <router-link to="/videos" class="bg-gray-500 p-4 text-white rounded-md">Videos</router-link>
    <router-link to="/reporting/interactions" class="bg-gray-500 p-4 text-white rounded-md">Interactions</router-link>
    
  </section>
</template>

<script setup>  
onMounted(() => {
});
</script>

<template>
  <div class="">
      <input
        type="text"
        :value="modelValue"
        @input="handleInput($event)"
        autocomplete="off"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
        :placeholder="label"
        :autofocus="autofocus"
      />
  </div>
</template>

<script setup>

defineProps({
  label: {
    type: String,
    default: 'Type here'
  },
  modelValue: {
    type: String,
    required: true
  },
  autofocus: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])

const handleInput = ($event) => {
  emit('update:modelValue', $event.target.value)
}
</script>

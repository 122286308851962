<template>
  <div class="text-center">    
    <div v-if="store.pagination">  

      <div class="grid grid-cols-1">
        <div class="col-span-2 text-2xl font-bold mb-4 text-left"> 
          {{ false && store.loading ? 'Loading...' : formatNumber(store.pagination.count || 0) }} 
          {{ false && store.loading ? '' : type == 0 ? $t('common.views') : type == 1 ? $t('common.interactions') : type == 2 ? $t('common.videos') : type == 3 ? $t('common.viewers') : type == 4 ? $t('common.groups') : type == 5 ? $t('common.series') : '' }} 
          <div class="text-sm font-normal"> 
            <span v-if="!store.loading"> {{ $t('pagination.page') }} {{ store.pagination.current}} {{ $t('common.of') }} {{ Math.max(1, store.pagination.pages) }} </span> <span v-else> &nbsp; </span> </div>
        </div>
        

        

        <!-- <div class="col-span-1 text-center mx-4">
          <vSelect 
            v-model="store.pagination.current" 
            id="pages" 
            @change="router.push({path: $route.path, query: setQuery(store.pagination.current)})" 
            :options="pageOptions"
            :reduce="page => page.value"
            :label="page => page.label"
          />
          
        </div> -->
        
      </div>
      
      
      <!-- Old -->
      <!-- <div v-if="false" class="grid grid-cols-6">
        <div class="col-span-1">
          <router-link :to="{path: $route.path, query: setQuery(1)}">
            <ButtonWhite :text="`${$t('pagination.first')} ${$t('pagination.page')} `"/>
          </router-link>
        </div>
        <div class="col-span-1">
          <router-link :to="{path: $route.path, query: setQuery(store.pagination.previous)}">
            <ButtonWhite :text="`${$t('pagination.previous')} ${$t('pagination.page')} `" />
          </router-link>
        </div>
        
        <div class="col-span-2 text-center">
          <select v-model="store.pagination.current" id="pages" @change="router.push({path: $route.path, query: setQuery(store.pagination.current)})" class="bg-gray-50 border border-gray-300 text-gray-900 text-md mx-auto rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5">
            <option v-for="page in store.pagination.pages" :key="page" :value="page"> {{ $t('pagination.page') }} {{ page }}</option>
          </select>
        </div>
        <div class="col-span-1">
          <router-link :to="{path: $route.path, query: setQuery(store.pagination.next)}">
            <ButtonWhite :text="`${$t('pagination.next')} ${$t('pagination.page')} `"/>
          </router-link>
        </div>
        <div class="col-span-1">
          <router-link :to="{path: $route.path, query: setQuery(store.pagination.pages)}">
            <ButtonWhite :text="`${$t('pagination.last')} ${$t('pagination.page')} `"/>
          </router-link>
        </div>
      </div> -->

      <!-- <ul class="pagination">
        <li v-if="store.pagination.previous != null">
          <router-link :to="{path: $route.path, query: setQuery(store.pagination.previous)}" v-if="store.pagination.previous">
            <span>&laquo;</span>
          </router-link>
        </li>

        <li v-for="page in store.pagination.pages" :key="page" :class="[store.pagination.current == page ? 'active' : '']">
          <router-link :to="{path: $route.path, query: setQuery(page)}" v-if="page != store.pagination.current && (page == 1 || page == 2 || page == 3 || page == store.pagination.pages || page == store.pagination.pages - 1 || page == store.pagination.pages - 2)">{{ page }}</router-link>

          <span v-if="page != store.pagination.current && page == 4">...</span>
          <span v-if="page == store.pagination.current">{{ page }}</span>
          <span v-if="page == store.pagination.current && page == 4">...</span>
        </li>

        <li v-if="store.pagination.next != null && store.pagination.pages > 0">
          <router-link :to="{path: $route.path, query: setQuery(store.pagination.next)}"  v-if="store.pagination.next">
            <span>&raquo;</span>
          </router-link>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script setup>
import ButtonWhite from './ButtonWhite.vue'
import { timeFormatter } from "../composables/TimeFormatter.js";
const { formatNumber } = timeFormatter();

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';



const clickEvent = defineEmits(['clicked'])
const location = useRoute();
const props = defineProps(['store', 'type']);
const router = useRouter();

watch(() => location.query, () => {
  clickEvent('clicked', true);
});

const setQuery = (page) => {
  const query = JSON.parse(JSON.stringify(location.query));
  query['page'] = page;
  return query;
}

</script>

<template>  
  <section class="container">
    <ul class="breadcrumb">
      <li><router-link :to="{ name: 'root_path' }">{{ $t('title') }}</router-link></li>
    </ul>
    Series: {{ store.series }}
    <button @click="deleteSeries">Delete</button>
  </section>
</template>

<script setup>
import { SeriesStore } from "../../stores/series_store.js";
const store = SeriesStore();
const location = useRoute();
const router = useRouter();
import { useRoute } from 'vue-router'


onMounted(() => {
  
  store.show(location.params.id)
});

const deleteSeries = () => {
  store.destroy(store.series.id).then(()=> {
    router.push({ name: 'index_series_path' })
  })
}
</script>
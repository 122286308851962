<template>
  <section class="container mx-auto">
    DESIGN
    
  </section>
</template>

<script setup>  
onMounted(() => {
});
</script>

import { createWebHistory, createRouter } from 'vue-router'

import PageIndex from './views/pages/index.vue';
import Dashboard from './views/pages/Dashboard.vue';
const LoginSuccess = () => import('./views/pages/LoginSuccess.vue');

// Reporting
const Reporting = () => import('./views/pages/Reporting.vue');

// Views
const IndexViews = () => import('./views/plays/index.vue');
const ShowView = () => import('./views/plays/show.vue');

// Viewers
const IndexViewers = () => import('./views/viewers/index.vue');
const ShowViewer = () => import('./views/viewers/show.vue');

// Videos
const IndexVideos = () => import('./views/videos/index.vue');
const ShowVideo = () => import('./views/videos/show.vue');

// Interactions
const IndexInteractions = () => import('./views/interactions/index.vue');
const ShowInteraction = () => import('./views/interactions/show.vue');

// Account
const Account = () => import('./views/users/Account.vue');


// User Integrations
const Integrations = () => import('./views/users/integrations/Home.vue');
const UserHubSpotIntegration = () => import('./views/users/integrations/HubSpot.vue');
const UserZapierIntegration = () => import('./views/users/integrations/Zapier.vue');
const UserGoogleAnalyticsIntegration = () => import('./views/users/integrations/GoogleAnalytics.vue');
const UserFacebookIntegration = () => import('./views/users/integrations/Facebook.vue');
const UserSynthesiaIntegration = () => import('./views/users/integrations/Synthesia.vue');
const UserShopifyIntegration = () => import('./views/users/integrations/Shopify.vue');

// Groups
const IndexGroups = () => import('./views/groups/index.vue');
const ShowGroup = () => import('./views/groups/show.vue');

// Pages

import Help from './views/pages/Help.vue';
import Affiliates from './views/pages/Affiliates.vue';
import Billing from './views/pages/Billing.vue';
import Assets from './views/pages/Assets.vue';
import Design from './views/pages/Design.vue';
import Organization from './views/pages/Organization.vue';

// Series
import IndexSeries from './views/series/index.vue';
import ShowSeries from './views/series/show.vue';

const router = createRouter({
  history: createWebHistory(`/${I18n.prefix}`),
  routes: [
    // Authentication
    { path: '/', component: Dashboard, name: 'root_path' },
    { path: '/login/success', component: LoginSuccess, name: 'login_success_path' },

    // Reporting home
    { path: '/reporting', component: Reporting, name: 'reporting_path' },
    // Views
    { path: '/reporting/views', component: IndexViews, name: 'index_views_path' },
    { path: '/reporting/views/:id', component: ShowView, name: 'show_view_path' },

    // Interactions
    { path: '/reporting/interactions', component: IndexInteractions, name: 'index_interactions_path' },
    { path: '/reporting/interactions/:id', component: ShowInteraction, name: 'show_interaction_path' },

    // Viewers
    { path: '/reporting/viewers', component: IndexViewers, name: 'index_viewers_path' },
    { path: '/reporting/viewers/:id', component: ShowViewer, name: 'show_viewer_path' },
    // 
    { path: '/videos', component: IndexVideos, name: 'index_videos_path' },
    { path: '/videos/:id', component: ShowVideo, name: 'show_video_path' },

    // Account
    { path: '/pages', component: PageIndex, name: 'pages_path' },
    { path: '/account', component: Account, name: 'account_path' },

    // Integrations
    { path: '/integrations', component: Integrations, name: 'integrations_path' },
    { path: '/integrations/hubspot', component: UserHubSpotIntegration, name: 'user_hubspot_integration_path' },
    { path: '/integrations/zapier', component: UserZapierIntegration, name: 'user_zapier_integration_path' },
    { path: '/integrations/google-analytics', component: UserGoogleAnalyticsIntegration, name: 'user_google_analytics_integration_path' },
    { path: '/integrations/facebook', component: UserFacebookIntegration, name: 'user_facebook_integration_path' },
    { path: '/integrations/synthesia', component: UserSynthesiaIntegration, name: 'user_synthesia_integration_path' },
    { path: '/integrations/shopify', component: UserShopifyIntegration, name: 'user_shopify_integration_path' },

    // Groups
    { path: '/groups', component: IndexGroups, name: 'index_groups_path' },
    { path: '/groups/:id', component: ShowGroup, name: 'show_group_path' },

    // Series
    { path: '/series', component: IndexSeries, name: 'index_series_path' },
    { path: '/series/:id', component: ShowSeries, name: 'show_series_path' },

    // Pages
    { path: '/help', component: Help, name: 'help_path' },
    { path: '/affiliates', component: Affiliates, name: 'affiliates_path' },
    { path: '/billing', component: Billing, name: 'billing_path' },
    { path: '/assets', component: Assets, name: 'assets_path' },
    { path: '/design', component: Design, name: 'design_path' },
    { path: '/organization', component: Organization, name: 'organization_path' },
  ]
});

// Handles 404 Not found
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    // window.location.href = '/404'
    console.log("No route")
  } else {
    next();
  }
});

export default router;